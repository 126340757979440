import Service from "../Service";
import Vue from "vue";

const resource = "User/";

export default {
    save(usr, requestID) {
        return Service.post(resource + "save", usr, {

        });
    },
    update(usr, requestID) {
        return Service.put(resource + "update", usr, {

        });
    },
    delete(usr, requestID) {
        return Service.delete(resource + "delete", {
            data: usr
        });
    },

    list(requestID) {
        return Service.post(resource + "list", {}, {
        });
    },

    getUrlSearchUser() {
        return Vue.prototype.$http.defaults.baseURL + "User/list";
    },

    pagination(parameters, requestID) {

        return Service.post(resource + "Pagination", parameters, {
            params: {},
        });
    },
    copyAllow(usr, usrs, requestID) {
        return Service.post(resource + "copyallow", usrs, {
            params: { usrID: usr.UsrID, requestID: requestID },
        });
    },

    updateUserFirma(usrID, photo) {
        return Service.post(resource + "updatefirma", "", {
            params: { usrID: usrID, photo: photo },
        });
    },

    CheckPasswordChange(email) {
        return Service.get(resource + "CheckPasswordChange", {
            params: {email},
        });
    },

    RegisterPasswordChange(request) {
        return Service.post(resource + "RegisterPasswordChange",request, {
            params: {},
        });
    },
};