
export default {

    //Message
    MSG_001: "Seguro de Guardar ?",
    MSG_002: "Seguro de Actualizar ?.",
    MSG_003: "Seguro de Eliminar ?.",
    MSG_004: "Agregado Correctamente.",
    MSG_005: "Actualizado Correctamente.",
    MSG_006: "Eliminado Correctamente.",



    //All
    FormatDateDB: "YYYY-MM-DD",
    FormatDateDBNiubiz: "YYYY-MM-DDTHH:mm:ss-0000",
    FormatDateTimeDB: "YYYY-MM-DD HH:mm",
    FormaTimeDB: "YYYY-MM-DD HH:mm",
    FormatDateView: "DD/MM/YYYY",
    FormatDateTimeView: "DD/MM/YYYY HH:mm",
    FormatTimeDB: " HH:mm",
    FormatTimeView: " HH:mm",
    FormatDateMask: "##/##/####",
    FormatDateTimeMask: "##/##/#### ##:##", 

    //Producción
    baseHomeExtern: "USER",  //SUPPLIER = PROVEEDOR, CUSTOMER = CLIENTE, USER = USUARIO INTERNO
    baseHomeURL: "/Interno", //Interno = /micuenta, Externo = /login, Maraton = /carrera
    baseURL: "https://190.223.56.84:8183/api/v1.0/",
    UrlPortal: "https://portal.imperu.com.pe", //http://localhost:9093


    //Local
    //baseHomeExtern: "SUPPLIER",  //SUPPLIER = PROVEEDOR, CUSTOMER = CLIENTE
    //baseHomeURL: "/micuenta",
    //baseURL: "https://localhost:7193/api/v1.0/",



    TypeFile: {
        EXCEL: { ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", Extension: "xlsx" },
        PDF: { ContentType: "application/pdf", Extension: "pdf" },
        ZIP: { ContentType: "application/pdf", Extension: "zip" },
    },

};