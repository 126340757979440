/* window.$ = window.jQuery = require("jquery"); */
window.$ = require("jquery");
window.jQuery = require("jquery");
import push from "push.js";

import Vue from 'vue'
import App from './Core.vue'
import router from './router'

//
/* 
 */
import BootstrapVue from "bootstrap-vue"
import ArgonDashboard from "./argon-dashboard";
import moment from "moment";
import VueMoment from "vue-moment";
import VueMask from "vue-the-mask";
import IdleVue from "idle-vue";
import _ from "lodash";
import CKEditor from 'ckeditor4-vue';
import rate from "vue-rate";




import store from "./store/";
import vuetify from "./plugins/vuetify.js";
import fun from "./plugins/fun";
import funv3 from "./plugins/funv3";

import constapp from "./plugins/const-es";
import base from "./plugins/base";
import axios from "./services/Service";
import axiosv3 from "./services/Servicev3";
//import './registerServiceWorker'
import vcNotification from './components/Utils/vcNotification.vue'; 
import { showWarningConsole } from "./plugins/warning.js";

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 10 minutos,
  startAtIdle: false
});
  
const eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;

const eventErr = new Vue();
Vue.prototype.$eventErr = eventErr;

Vue.use(_);
Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(CKEditor);
Vue.use(rate);

Vue.prototype.moment = moment;
Vue.prototype.$const = constapp;
Vue.prototype.$fun = fun;
Vue.prototype.$funv3 = funv3;
Vue.prototype.$push = push;
Vue.prototype.$http = axios;

function showNotification(error){

        const ComponenteError = Vue.extend(vcNotification);
        // Montar el componente en un elemento nuevo
        const errorComponent = new ComponenteError({
            propsData: {
                message: error
            }
          }).$mount();
      
        // Agregar el componente al DOM en el lugar donde deseas mostrarlo
        document.body.appendChild(errorComponent.$el);

}


axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token != null) {
            config.headers.common["Authorization"] = "Bearer " + token;
            config.headers.common["X-Role"] = "Imp-User";
            config.headers.common["CpyID"] = fun.getCompanyID()
            config.headers.common["UsrID"] = fun.getUserID()
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosv3.interceptors.request.use(
    (config) => {
        console.log("config xcccc-->", config);
        const token = localStorage.getItem("token_v3");
        console.log("token_v3 xcccc-->", token);
        console.log("fun.getCompanyID()-->", fun.getCompanyID());
        console.log("fun.getUserID()-->", fun.getUserID());
        if (token != null) {
            config.headers.common["Authorization"] = "Bearer " + token;
            config.headers.common["CpyID"] = fun.getCompanyID()
            config.headers.common["UsrID"] = fun.getUserID()
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {

        
        if (error.response == null) {
            fun.alertFull("Error de Conexión", "error");
            return;
        }      

        
        var errors = error.response.data
        var messageResponse = "[Error]"

        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    
                    messageResponse = errors
                    showNotification(messageResponse)
                    break;
                case 401:
                    Vue.prototype.$eventBus.$emit('sessionExpired');
                    break;
                case 403:
                    fun.alert("Solicitud denegada.", "warning");
                    break;
                case 404:

                    if (localStorage.getItem("token") != null) {
                        fun.alert("El servicio solicitado no existe.");
                    } else {
                        router.push(constapp.baseHomeURL);
                    }
                    break;
                case 500:
                    if (localStorage.getItem("token") != null) {
                        fun.alert(error.response.data.ExceptionMessage, "error");
                    } else {
                        fun.alert("[Connection Failed]", "error");
                    }

                    localStorage.setItem("isIdle", false);
                    localStorage.clear();
                    localStorage.removeItem("token");
                    router.push(constapp.baseHomeURL);

                    break;
                case 502:
                    if (localStorage.getItem("token") != null) fun.alert(messageResponse, "error");
                    break;
            }
            return Promise.reject(error);
        }
    }
);


axiosv3.interceptors.response.use(
    (response) => {
        console.log("response xcccc-->", response);
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    (error) => {
        console.log("error xcccc-->", error);
        
        if (error.response == null) {
            fun.alertFull('Error de Conexión', 'error');
            return Promise.reject(error);
        }  

        
        var errors = error.response.data.errors;
        var messageResponse = '[Error]';

        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    
                    messageResponse = errors
                    showNotification(messageResponse)
                    if(messageResponse.message){
                        fun.alertFull(messageResponse.message,"error")
                    }
                    break;
                case 401:
                    //showNotification("Solicitud denegada, Usuario no autenticado.");
                    fun.alertFull("Solicitud denegada, Usuario no autenticado.", "error");
                    Vue.prototype.$eventBus.$emit('sessionExpired');
                    break;
                case 403:
                    fun.alert("Solicitud denegada.", "warning");
                    break;
                case 404:

                    if (localStorage.getItem("token_v3") != null) {
                        fun.alert("El servicio solicitado no existe.");
                    } else {
                        //router.push(constapp.baseHomeURL);
                    }
                    break;
                case 500:
                    if (localStorage.getItem("token_v3") != null) {
                        fun.alert(error.response.data.ExceptionMessage, "error");
                    } else {
                        fun.alert("[Connection Failed]", "error");
                    }

                    localStorage.setItem("isIdle", false);
                    localStorage.clear();
                    localStorage.removeItem("token_v3");
                    //router.push(constapp.baseHomeURL);

                    break;
                case 502:
                    if (localStorage.getItem("token_v3") != null) fun.alert(messageResponse, "error");
                    break;
            }
            return Promise.reject(error);
        }
    }
);

// Load Locales ('en' comes loaded by default)
require("moment/locale/es");

// Choose Locale
moment.locale("es");

Vue.use(VueMoment, { moment });
Vue.directive("focus", {
    inserted: function(el) {
        el.firstChild.firstChild.childNodes[1].childNodes[1].focus();
    },
});

Vue.config.productionTip = false;
Vue.config.silent = true
Vue.config.devtools = false


Vue.config.errorHandler = function (err, vm, info) {
    // Aquí puedes registrar el error en una herramienta de seguimiento de errores o simplemente imprimirlo en la consola.
   /*  console.error('Error global:', err);
    console.error('Error vm:', vm);
    console.error('Error info:', info);
    showNotification(err) */

    Vue.prototype.$eventErr.$emit('errorGlobal', info);
    
  };

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");

function cargarPagina() {
    showWarningConsole();
}
window.onload = cargarPagina;

// Configuración del Service Worker para recargar en caso de actualización
if ("serviceWorker" in navigator) {
    navigator.serviceWorker.addEventListener("controllerchange", () => {
        window.location.reload();
    });
}




