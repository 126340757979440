import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";
import _fun from '@/plugins/fun';
import _constapp from "@/plugins/const-es";

import { children as general } from "@/router//general.js";
import { children as security } from "@/router//security.js";
import { children as logistics } from "@/router//logistics.js";
import { children as querymanager } from "@/router//querymanager.js";
import { children as card } from "@/router//card.js";
import { children as marketing } from "@/router//marketing.js";
import { children as collection } from "@/router//collection.js";
import { children as accounting } from "@/router//accounting.js";

Vue.use(VueRouter, {
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },

});
let children = [];
children.push(...general);
children.push(...security);
children.push(...logistics);
children.push(...querymanager);
children.push(...card);
children.push(...marketing);
children.push(...collection);
children.push(...accounting);


const routes = [{
        path: "*",
        redirect: "/login",
        
    },
    {
        path: "/",
        name: "HOME",
        component: Home,
        meta: {
            authenticated: true,
            breadcrumb: [{ text: "Inicio", disabled: false, href: "/#/" }],
        },
        children: children,
    },
    {
        path: "/login",
        name: "MICUENTA",
        breadcrumb: [{
            text: "micuenta",
            disabled: false,
            href: "micuenta",
        }, ],
        component: () =>
            import ("../views/Auth/Login.vue"),
    },
    {
        path: "/login",
        name: "LOGIN",
        breadcrumb: [{
            text: "login",
            disabled: false,
            href: "login",
        }, ],
        component: () =>
           /*  import ("../views/Marathon/MarMarathon.vue"), */
            import ("../views/Auth/LoginExtern.vue"), 
            // CAMBIAR PARA VER OTRAS COMPAÑIAS CVIERA
            
    },
    {
        path: "/carrera",
        name: "MARATHON",
        breadcrumb: [{
            text: "carrera",
            disabled: false,
            href: "carrera",
        }, ],
        component: () =>
           import ("../views/Marathon/MarMarathon.vue"),
            
    },
    {
        path: "/account/validate/",
        name: "VALIDATE_ACCOUNT",
        breadcrumb: [{
            text: "validate",
            disabled: false,
            href: "validate",
        }, ],
        component: () =>
            import ("../views/Auth/AccountValidate.vue"),
    },
    {
        path: "/account/recovery/",
        name: "RECOVERY_ACCOUNT",
        breadcrumb: [{
            text: "recovery",
            disabled: false,
            href: "recovery",
        }, ],
        component: () =>
            import ("../views/Auth/ValidateRecoveryPassword.vue"),
    },
];


const router = new VueRouter({
    mode: "history",
    routes,
});




router.beforeEach((to, from, next) => {
    let userMenu = JSON.parse(localStorage.getItem("UserOptions"));
    let usrExtern = JSON.parse(localStorage.getItem("UsrExtern")) || true


    let activeSession = localStorage.getItem("UsrID") != null ? true : false;
    var params = _fun.searchObject(userMenu == null ? [] : userMenu, to.name)

    let authenticated = to.matched.some((record) => record.meta.authenticated);
    const token = localStorage.getItem("token");


    if (to.matched.some(record => record.meta.authenticated) ) {
        if (token == null) {
            next(_constapp.baseHomeURL)
            /* if(usrExtern){
                next('/login');
            }else{
                next('/micuenta');
            } */
        } 
    } 

    if (to.name.toUpperCase() != "HOME" && to.name.toUpperCase() != "MICUENTA" && to.name.toUpperCase() != "LOGIN" && to.name.toUpperCase() != "VALIDATE_ACCOUNT" && to.name.toUpperCase() != "RECOVERY_ACCOUNT" && to.name.toUpperCase() != "MARATHON" ) {
       
        if (params !== null) {

            to.params.security = params;

            if (authenticated && !activeSession) next(_constapp.baseHomeURL);
            else if (!authenticated && activeSession) next("");
            else next();
        } 

    /* } else if (authenticated && !activeSession) if(usrExtern) next("carrera"); else next("carrera"); */
    } else if (authenticated && !activeSession) next(_constapp.baseHomeURL);

    else if (!authenticated && activeSession) {
        next("");
    } else if (to.name.toUpperCase() == "HOME" && localStorage.getItem("UsrID") != null) {
        next();
    } else {   
        next();
    }
});

export default router;