<template>
<div>
    <!-- <vc-notification :message="messageResponse" v-if="messageResponse.length > 0" style="z-index:9999999"/> -->
    <v-dialog v-if="showLocal" v-model="showLocal" :persistent="messageResponse.length==0" width="400">
        <v-row style="margin:auto">

            <v-card color="white" style="border-radius: 6px;opacity: 0.9;">

                <v-progress-linear model-value="10" striped indeterminate color="light-blue" class="mb-0 mt-0"></v-progress-linear>
                <v-card-text v-if="messageResponse.length == 0">

                    <div class="font-icon-wrapper float-center mr-3 mb-3">
                        <div class="loader-wrapper d-flex justify-content-center align-items-center">
                            <clip-loader :color="'blue'"></clip-loader>
                        </div>
                        <p></p>
                    </div>

                    <h5 style="color:black;text-align: center;margin-top:-10px" class="pt-0">{{title}}</h5>
                    <h6 style="color:black;text-align: center;margin-bottom:-10px" class="pt-0">Por favor espere...</h6>

                </v-card-text>
                <v-card-text v-else>
                    <div class="pt-0" style="color: black">
                        <h5>Errores encontrados: </h5>
                        <br />
                        {{messageResponse}}

                    </div>
                </v-card-text>
                <!-- <v-progress-linear model-value="100" striped color="light-blue"></v-progress-linear> -->
                <v-progress-linear model-value="10" striped indeterminate color="light-orange" class="mb-0 mt-0"></v-progress-linear>
            </v-card>

        </v-row>
    </v-dialog>
</div>
</template>

<script>
import ClipLoader from "vue-spinner/src/RiseLoader.vue";

export default {
    components: {
        ClipLoader

    },
    props: {
        title: {
            type: String,
            default: "Procesando"
        },
        processing: {
            type: Boolean,
            default: false
        },
        errors: null
    },
    data: () => ({
        messageResponse: ""
    }),

    created() {
        this.messageResponse = ""
        this.$eventErr.$on('errorGlobal', (item) => {
            this.messageResponse = item
        });
    },

    computed: {
        showLocal: {
            get: function () {
                this.messageResponse = ""
                return this.processing
            },
            set: function (value) {
                this.$emit('close')
            }
        },
    },

    watch: {
        errors() {
            // console.log("watch",watch);
            console.log("loading",this.errors);

            if (this.errors == null || this.errors == undefined) {
                this.$fun.alertFull("Error de Conexión | Error no interceptado.", "error");
                this.showLocal = false
                return;
            }

            var error = this.errors.data;

            switch (this.errors.status) {
                case 400:

                    /*  if(error.Errors.length > 0){
                         this.messageResponse = error.Errors.join(", ")
                     }else if(error.Errors.length <= 0 && error.Message !== null){
                         this.messageResponse = error.Message

                     }else{
                         this.messageResponse = error
                     } */
                    this.messageResponse = error
                    break;
                case 401:
                    this.messageResponse = "[No Autorizado]"
                    break;
                case 403:
                    this.messageResponse = "[Solicitud denegada]"
                    break;
                case 404:
                    break;
                case 500:
                    this.messageResponse = "[Connection Failed]"

                    break;
                case 502:
                    break;
            }

        }

    }

};
</script>

<style lang="scss" scoped>
</style>
